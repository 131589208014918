import { API } from "aws-amplify";
import store from "../store";

export async function getCampaignAccessCacheByCodeCheckVoucherLimit(code) {
  code = code.toLowerCase().trim();
  const getCampaignByAccessCode = /* GraphQL */ `
    query getCampaign_access_by_code_voucher_limit_check($code: String!) {
      getCampaign_access_by_code_voucher_limit_check(code: $code) {
        voucher_count
        code
        domain
        organisation_active
        start_date
        end_date
        employee_id
        employee_id_label
        employee_id_min
        employee_id_max
        employee_id_pattern
      }
    }
  `;
  try {
    if (store.state.campaignDetails === null) {
      var {
        data: { getCampaign_access_by_code_voucher_limit_check: campaign },
      } = await API.graphql({
        query: getCampaignByAccessCode,
        variables: {
          code: code,
        },
        authMode: "API_KEY",
      });
    } else {
      var campaign = store.state.campaignDetails;
    }
    store.dispatch("campaignDetailsAction", campaign);
    return campaign;
  } catch (e) {
    throw new Error(e.errors[0].message);
  }
}

export async function getCampaignAccessCacheByCode(code) {
  code = code.toLowerCase().trim();
  const getCampaignByAccessCode = /* GraphQL */ `
    query GetCampaign_access_by_code($code: String!) {
      getCampaign_access_by_code(code: $code) {
        code
        domain
        organisation_active
        start_date
        end_date
        employee_id
        employee_id_label
        employee_id_min
        employee_id_max
        employee_id_pattern
      }
    }
  `;
  try {
    if (store.state.campaignDetails === null) {
      var {
        data: { getCampaign_access_by_code: campaign },
      } = await API.graphql({
        query: getCampaignByAccessCode,
        variables: {
          code: code,
        },
        authMode: "API_KEY",
      });
    } else {
      var campaign = store.state.campaignDetails;
    }
    store.dispatch("campaignDetailsAction", campaign);
    return campaign;
  } catch (e) {
    throw new Error(e.errors[0].message);
  }
}

export function getCampaignVerificationDetails(code) {
  code = code.toLowerCase().trim();
  const getVerifyCampaignDetails = /* GraphQL */ `
    query GetVerify_campaign_details($code: String!) {
      getVerify_campaign_details(code: $code) {
        code
        voucher_limit
        registered_count
        organisation_active
        start_date
        end_date
        employee_id
      }
    }
  `;
  var output = new Promise(async function (resolve, reject) {
    const {
      data: { getVerify_campaign_details: campaign },
    } = await API.graphql({
      query: getVerifyCampaignDetails,
      variables: {
        code: code,
      },
      authMode: "API_KEY",
    });

    if (campaign === null) {
      reject("Code not recognised, please try again.");
    }
    return resolve(campaign);
  });
  return output;
}

export function isCampaignAutoDomain(domain) {
  domain = domain.toLowerCase().trim();
  const getCampaign_access_by_auto_domain = /* GraphQL */ `
    query GetCampaign_access_by_auto_domain($domain: String!) {
      getCampaign_access_by_auto_domain(domain: $domain) {
        code
        domain
        auto_domain
        employee_id
      }
    }
  `;
  var output = new Promise(async function (resolve, reject) {
    const {
      data: { getCampaign_access_by_auto_domain: campaign },
    } = await API.graphql({
      query: getCampaign_access_by_auto_domain,
      variables: {
        domain: domain,
      },
      authMode: "API_KEY",
    });

    if (campaign === null) {
      return resolve(false);
    }
    return resolve(campaign);
  });
  return output;
}
