<template>
  <div>

<!--      <div class="row mt-5">-->
<!--          <div class="col-12">-->
<!--              <div class="card">-->
<!--                  <div class="card-body">-->
<!--                      <p class="text-center">-->
<!--                        Flu Voucher season is now over until later this year. We are expecting to re-open our portal in June/July 2023.-->
<!--                      </p>-->
<!--                      <p class="text-center">-->
<!--                          Please visit <a href="https://www.digitalflu.co.uk">https://www.digitalflu.co.uk</a> for further details of our digital flu vouchers or contact <a href="mailto:flu@healthyperformance.co.uk">flu@healthyperformance.co.uk</a> should you have any questions.-->
<!--                      </p>-->
<!--                  </div>-->
<!--              </div>-->
<!--          </div>-->
<!--      </div>-->

    <div class="row mt-5 voucher-home justify-content-center">
      <div class="col-10 left-box text-white p-4 mr-lg-1">
        <h2 class="mt-1">Get my voucher here</h2>

        <div class="mb-3">
          <div v-if="error">
            {{ errorMessage }}
          </div>
          <b-form @submit.stop.prevent="onVoucherSubmit">
            <div class="d-flex">
              <div class="form-group flex-grow-1 mb-0 mr-1">
                <b-form-input
                  type="text"
                  class="p-4"
                  placeholder="Enter your email address"
                  v-model="voucherForm.email"
                  :state="validateVoucherState('email')"
                  aria-describedby="input-voucher-email"
                ></b-form-input>
                <b-form-invalid-feedback
                  id="input-voucher-email"
                  style="font-size: small !important"
                >
                  Email is required
                </b-form-invalid-feedback>
              </div>
              <button
                type="submit"
                class="btn btn-light btn-lg btn-voucher btn-voucher text-hpcolour-green"
              >
                Go
              </button>
            </div>
          </b-form>
        </div>

        <p>Enter your email to receive or resend your voucher</p>
      </div>
<!--      <div class="col-12 col-lg mt-lg-0 mt-1 right-box text-white p-4">-->
<!--        <h2 class="mt-1">Step 2: Find a pharmacy here</h2>-->

<!--        <div class="mb-3">-->
<!--          <b-form @submit.stop.prevent="onFindSubmit">-->
<!--            <div class="d-flex">-->
<!--              <div class="form-group flex-grow-1 mb-0 mr-1">-->
<!--                <b-form-input-->
<!--                  type="text"-->
<!--                  class="p-4"-->
<!--                  placeholder="Enter postcode"-->
<!--                  v-model="findForm.postcode"-->
<!--                  :state="validateFindState('postcode')"-->
<!--                  aria-describedby="input-find-postcode"-->
<!--                ></b-form-input>-->
<!--                <b-form-invalid-feedback-->
<!--                  id="input-find-postcode"-->
<!--                  style="font-size: small !important"-->
<!--                >-->
<!--                  Postcode is required-->
<!--                </b-form-invalid-feedback>-->
<!--              </div>-->
<!--              <button-->
<!--                type="submit"-->
<!--                class="btn btn-light btn-voucher btn-lg text-hpcolour-orange"-->
<!--              >-->
<!--                Find-->
<!--              </button>-->
<!--            </div>-->
<!--          </b-form>-->
<!--        </div>-->

<!--        <p>Enter your postcode to locate the nearest pharmacies</p>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";
import voucherFactory from "./../modules/AssignVoucher";
import * as CampaignAccessCache from "./../modules/CampaignAccessCache";

export default {
  name: "Home22",
  components: {},

  mixins: [validationMixin],

  data() {
    return {
      voucherForm: {
        email: null,
      },
      findForm: {
        postcode: null,
      },
      error: false,
      errorMessage: null,
    };
  },

  validations: {
    voucherForm: {
      email: {
        required,
        minLength: minLength(3),
        email,
      },
    },
    findForm: {
      postcode: {
        required,
        minLength: minLength(3),
      },
    },
  },

  created() {
    // Reset local and vuex variable for sanity
    localStorage.removeItem("hpFluEmployeeNumber");
    localStorage.removeItem("hpFluPostcode");
    localStorage.removeItem("hpFluEmail");
    localStorage.removeItem("hpFluCampaignCode");
    this.$store.dispatch("campaignDetailsAction", null);
    this.$store.dispatch("findForm/clearPostcode");
  },

  methods: {
    validateVoucherState(name) {
      const { $dirty, $error } = this.$v.voucherForm[name];
      return $dirty ? !$error : null;
    },

    validateFindState(name) {
      const { $dirty, $error } = this.$v.findForm[name];
      return $dirty ? !$error : null;
    },

    navigatePage(page, params) {
      if (params) {
        this.$router.push({ name: page, params: params }).catch(() => {});
        return;
      }
      this.$router.push({ name: page }).catch(() => {});
    },

    async onVoucherSubmit() {
      this.$v.voucherForm.$touch();
      if (this.$v.voucherForm.$anyError) {
        return;
      }

      const voucher = await voucherFactory(
        this.voucherForm.email,
        null,
        "checkemail"
      );
      // console.log(voucher, "voucher");
      if (!voucher) {
        // Email not found route to register campaign code
        localStorage.hpFluEmail = this.voucherForm.email;

        // Check auto domain set
        const emailDomain = this.voucherForm.email.split("@").pop();
        const isAutoDomain = await CampaignAccessCache.isCampaignAutoDomain(
          emailDomain
        );
        // console.log(isAutoDomain, "isAutoDomain");

        if (!isAutoDomain) {
          this.navigatePage("CampaignCode");
          return;
        }
        localStorage.hpFluCampaignCode = isAutoDomain.code;
        const renderEmployeePage = isAutoDomain.employee_id ? true : false;
        // console.log(renderEmployeePage, "renderEmployeePage");
        if (renderEmployeePage) {
          this.navigatePage("Employee", { code: isAutoDomain.code });
          return;
        }
        // No longer need the questions
        // this.navigatePage("PreRegister", { code: isAutoDomain.code });
        this.navigatePage("Postcode", { code: this.$route.params.code });

        return;
      }
      // Resend voucher
      // console.log("resend");
      this.resendVoucher(this.voucherForm.email, voucher.access_code);
    },

    async onFindSubmit() {
      this.$v.findForm.$touch();
      if (this.$v.findForm.$anyError) {
        return;
      }

      await this.$store.dispatch(
        "findForm/setPostcode",
        this.findForm.postcode
      );
      this.navigatePage("Find");
    },

    async resendVoucher(email, campaignCode) {
      localStorage.hpFluCampaignCode = campaignCode;
      localStorage.hpFluEmail = email;
      this.navigatePage("CampaignCode");
    },
  },

  mounted: function () {
    /*this.$nextTick(function () {
      this.$v.voucherForm.$touch();
      this.$v.findForm.$touch();
    });*/
  },
};
</script>
