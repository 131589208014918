import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "../public/css/style.scss";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";

// Amplify.configure({
//   "aws_project_region": "eu-west-2",
//   "aws_appsync_graphqlEndpoint": "https://dg3wtio2kzgr3ctcuwm3it4xbm.appsync-api.eu-west-2.amazonaws.com/graphql",
//   "aws_appsync_region": "eu-west-2",
//   "aws_appsync_authenticationType": "API_KEY",
//   "aws_appsync_apiKey": "da2-7y4gzrkmjrcxtcvduil2jzrc7q"
// });

Amplify.configure(awsconfig);

Vue.use(require("vue-moment"));
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");