// initial state
const state = () => ({
  postcode: null,
});

// getters
const getters = {
  postcode: (state) => {
    return state.postcode
  },
};

// actions
const actions = {
  setPostcode({ commit }, postcode) {
    commit("setPostcode", postcode);
  },

  clearPostcode({ commit }) {
    commit("setPostcode", null);
  },
};

// mutations
const mutations = {
  setPostcode(state, postcode) {
    state.postcode = postcode;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
