import Vue from "vue";
import Vuex from "vuex";

import findForm from "./modules/findForm";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    campaignDetails: null
  },
  mutations: {
    setCampaignDetails(state, payload) {
      state.campaignDetails = payload;
    },
  },
  actions: {
    campaignDetailsAction({ commit }, payload) {
      commit("setCampaignDetails", payload);
    },
  },
  modules: {
    findForm
  },
});
